<template>
  <section>
    <div class="position-relative slide-section" :class="classes">
      <div class="slideshow">
        <div id="slideshow__ken__burns" v-if="loaded">
          <div v-for="(image, key) in images" :key="key" :style="{'backgroundImage' : `url(${image})`}"></div>
        </div>
        <div class="trinagle-bottom"></div>
      </div>

      <div class="w-100 position-absolute w-100 h-100 top-0 z-index-max">
        <h1 v-if="sliderPageTitle" class="hero-title" >
          <span v-html="sliderPageTitle" class="jd-font-bold text-uppercase jd-text-58 jd-text-28__mobile"></span>
        </h1>
        <div v-if="showCall" class="call-text d-none d-lg-block">
          <p data-aos="left-right" class="jd-font-bold">IT'S ALWAYS BETTER TO TALK</p>
          <p data-aos="right-left" class="jd-font-bold"><a :href="`tel:${SITE_DETAILS.phone}`">
            {{ SITE_DETAILS.phone }}</a>
            <span class="text-blue">CALL US NOW!</span>
          </p>
        </div>

        <b-container class="position-relative w-100 h-100 d-none d-lg-block" v-if="!withoutHero">
          <b-img src="/img/home/truck-hero.webp" class="home-heroes" width="500px"></b-img>
        </b-container>
        <quote-form :shown.sync="openQuote"  v-if="showQuote"></quote-form>
        <join-form :shown.sync="openJoin"  v-if="!showQuote" @close="openJoin = false"></join-form>
      </div>
    </div>

    <b-container class="d-block d-lg-none" v-if="!showQuote">
      <section class="quote-mobile-slider">
        <section class="position-relative index-200">
          <h3 class="jd-text-light jd-font-bold mb-3 jd-text-19 text-center jd-text-18__mobile get--quote-free-text text-primary">
            JOIN US HERE</h3>
          <b-form-group class="mb-4 position-relative">
            <b-input type="text" placeholder="Name"
                     :class="`jd-input hover-show-tooltip`"
                     @focus="focusInputJoin"
                     autocomplete="off"
                     name="name"></b-input>
          </b-form-group>
          <b-form-group class="mb-4 position-relative">
            <b-input type="text" placeholder="Company Name"
                     :class="`jd-input hover-show-tooltip`"
                     @focus="focusInputJoin"
                     autocomplete="off"
                     name="name"></b-input>
          </b-form-group>
          <b-form-group class="mb-4 position-relative">
            <b-input type="text" placeholder="Email"
                     :class="`jd-input hover-show-tooltip`"
                     @focus="focusInputJoin"
                     autocomplete="off"
                     name="phone"></b-input>
          </b-form-group>

          <b-form-group class="mb-4 position-relative">
            <b-input type="text" placeholder="Phone"
                     :class="`jd-input hover-show-tooltip`"
                     @focus="focusInputJoin"
                     autocomplete="off"
                     name="phone"></b-input>
          </b-form-group>

          <b-form-group class="mb-4 position-relative">
            <b-textarea type="text" placeholder="Enter Comment"
                        :class="`jd-input hover-show-tooltip`"
                        @focus="focusInput"
                        autocomplete="off"
                        rows="4"
                        name="phone"></b-textarea>
          </b-form-group>

          <b-btn variant="primary" block
                 @click="$root.$emit('openJoin')"
                 id="click-ios"
                 class="submit-btn-qoute jd-text-12 jd-text-14__mobile p-1 p-md-0 br-150 text-uppercase">Sign up!
          </b-btn>
        </section>
      </section>
    </b-container>

    <b-container class="d-block d-lg-none" v-if="showQuote">
      <section class="quote-mobile-slider">
        <section class="position-relative index-200">
          <h3 class="jd-text-light jd-font-medium mb-3 jd-text-16 text-center jd-text-18__mobile get--quote-free-text">
            GET YOUR FREE QUOTE</h3>
          <b-form-group class="mb-4 position-relative">
            <b-input type="text" placeholder="Name"
                     :class="`jd-input hover-show-tooltip`"
                     @focus="focusInput"
                     autocomplete="off"
                     name="name"></b-input>
          </b-form-group>
          <b-form-group class="mb-4 position-relative">
            <b-input type="text" placeholder="Email"
                     :class="`jd-input hover-show-tooltip`"
                     @focus="focusInput"
                     autocomplete="off"
                     name="phone"></b-input>
          </b-form-group>

          <b-form-group class="mb-4 position-relative">
            <b-input type="text" placeholder="Phone"
                     :class="`jd-input hover-show-tooltip`"
                     @focus="focusInput"
                     autocomplete="off"
                     name="phone"></b-input>
          </b-form-group>

          <b-form-group class="mb-4 position-relative">
            <b-textarea type="text" placeholder="Enter Comment"
                     :class="`jd-input hover-show-tooltip`"
                     @focus="focusInput"
                     autocomplete="off"
                        rows="4"
                     name="phone"></b-textarea>
          </b-form-group>

          <b-btn variant="primary" block
                 @click="$root.$emit('openQuote')"
                 id="click-ios"
                 class="submit-btn-qoute jd-text-12 jd-text-14__mobile p-1 p-md-0 br-150 text-uppercase">Let’s go!
          </b-btn>
        </section>
      </section>
    </b-container>
  </section>
</template>

<script>
import QuoteForm from "../../../components/QuoteForm";
import JoinForm from "../../../components/JoinForm";
import {mapGetters} from "vuex";
import Datepicker from 'vuejs-datepicker';

export default {
  props: {
    withoutHero: {
      type: Boolean,
      default: false
    },
    isBackground: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: function () {
        return []
      }
    },
    classes: {
      type: String,
      default: ""
    },
    showCall: {
      type: Boolean,
      default: false
    },
    sliderPageTitle: {
      type: String,
      default: ''
    },
    showQuote: {
      type: Boolean,
      default: true
    }
  },
  components: {QuoteForm, Datepicker,JoinForm},
  data() {
    return {
      openQuote: false,
      openJoin: false,
      loaded: false
    }
  },
  methods: {
    getAnimationPosition(key) {
      const animations = ['in', 'out'];
      return key % 2 === 0 ? animations[0] : animations[1];
    },
    focusInput() {
      window.blur();
      document.activeElement.blur()
      document.body.firstElementChild.tabIndex = 1;
      window.$('#click-ios').click();
    },
    focusInputJoin() {
      window.blur();
      document.activeElement.blur()
      document.body.firstElementChild.tabIndex = 1;
      window.$('#click-ios').click();
    }
  },
  created() {
    this.$root.$on('openQuote', () => {
      this.openQuote = !this.openQuote;
    })

    this.$root.$on('openJoin', () => {
      this.openJoin = !this.openJoin;
    })
  },
  mounted() {
    this.loaded = true;
  },
  computed: {
    ...mapGetters(['SITE_DETAILS']),
  }
}
</script>

<style scoped lang="scss">
@import "../../../utils/ken-burns/main.css";

.quote-mobile-slider {
  position: relative;
  padding: 15px;
  margin-top: -60px;
  z-index: 500;
  margin-bottom: 40px;
  .get--quote-free-text {
    color: #ef4e23;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(30px);
    z-index: 1;
    border-radius: 10px;
    background: rgba(0,0,0,0.05);
    @-moz-document url-prefix() {
      filter: blur(300px);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#444549), to(rgba(255, 255, 255, 0.5)));
    }
  }


  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.2);
    backdrop-filter: blur(27px);
    z-index: 0;
    border-radius: 10px;
    @-moz-document url-prefix() {
      filter: blur(27px);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#444549), to(rgba(255, 255, 255, 0.5)));
    }
  }
}
.index-200 {
  z-index: 200;
}
.hero-title {
  text-align: right;
  padding-right: calc(320px + 130px);
  padding-top: 20px;
  @media screen and (max-width: 991px) {
    text-align: left;
    padding-left: 1rem;
    padding-right: 0;
    line-height: 0.7;
  }

  span {
    text-shadow: 0 0 30px #000000;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: right;
    color: rgba(255,255,255, 0.7);
    ::v-deep span {
      -webkit-backdrop-filter: blur(25px) !important;
      backdrop-filter: blur(30px) !important;
      color: rgba(255,255,255, 0.9) !important;
    }
  }
}

.trinagle-bottom, .call-text {
  position: absolute;
  width: 100%;
  height: 100px;
  transform: rotate(-5deg);
  bottom: -290px;
  z-index: 10;
  background: #fff;
  padding: 0 20px;
  box-sizing: initial;
  left: 0;
  border-bottom: 280px solid #fff;
}

.call-text {
  background: transparent;
  bottom: 32px;
  text-align: center;
  font-size: 36px;
  color: #ffffff;
  z-index: 999999999;
  border-bottom: 0;
  @media screen and (max-width: 1300px) {
    font-size: 20px;
    bottom:14px;
  }
  p {
    margin-bottom: -25px;
    @media screen and (max-width: 1300px) {
      margin-bottom: -13px;
    }
    &:first-child {
      margin-left: -10%;
    }
  }
}

.slide-section {
  height: 60vh;
}

.slideshow {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


.top-0 {
  top: 0;
}

.home-heroes {
  position: absolute;
  bottom: -50px;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  @media screen and (min-width: 1920px) {
    width: 700px;
  }
  @media screen and (max-width: 1920px) {
    width: 500px;
  }
  @media screen and (max-width: 992px) {
    bottom: -50px;
    width: 420px;
  }

  @media screen and (max-width: 560px) {
    bottom: -20px;
    width: 280px;
  }
}

.z-index-max {
  z-index: 980;
}

/deep/ .vue-flux {
  animation: zoominoutsinglefeatured 20s infinite;
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.4, 1.4);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
